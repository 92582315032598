<template>
  <v-container>
    <v-row>
      <v-col class="mt-15">

      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-center">
          <v-pagination
              v-if="pageInfo.numberOfPages > 1"
              v-model="pageInfo.page"
              :length="pageInfo.numberOfPages"
              :total-visible="7"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: 'NewsletterTableView',
  props: {},
  data: () => ({
    pageInfo: {
      page: 1,
      pageSize: 50,
      numberOfPages: 1,
      total: 0
    },
    q: null,
    showError: false,
    error: null,
    loading: false,
    items: []
  }),
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    console.log("NewsletterTableView::created");
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData() {
      console.log("NewsletterTableView::fetchData");
      this.error = this.post = null
      this.loading = true
      let self = this;
      this.$http.get(`${process.env.VUE_APP_ENDPOINT}/admin/newsletter-list`,
          {
            page: self.pageInfo.page,
            pageSize: self.pageInfo.pageSize,
            q: self.q,
          }
      )
          .then(function (response) {
            self.items = response.data.records;
            self.pageInfo = response.data.pageInfo;
          })
          .catch(function (error) {
            if (error.response.status === 500) {
              self.error = "Da ist was schief gelaufen...";
            } else {
              self.error = error.response;
            }
            self.showError = true
          })
          .finally(() => this.loading = false)
      ;
    }
  }
}
</script>
